import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage, ImageDataLike } from 'gatsby-plugin-image';
import { usePageContext, IPage } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import {
    section,
    sectionConfigurator,
    sectionContentBox,
    title,
    subtitle,
    text,
} from './section-ecommerce-configurator.module.scss';
import { useT } from '../../hooks/use-translation';

import Section from '../hoc/section';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { getNodes } from '../../utils/get-nodes';

interface ISectionEcommerceConfiguratorProps {
    className?: string;
    id?: string;
}

interface ISectionEcommerceConfiguratorQueryResult {
    image: ImageDataLike;
    allPage: IQueryAllResult<Pick<IPage, 'locale' | 'pathname'>>;
}

const SectionEcommerceConfigurator: React.FC<ISectionEcommerceConfiguratorProps> = ({
    className = '',
    id,
}) => {
    const { locale } = usePageContext();
    const { image, allPage } = useStaticQuery<ISectionEcommerceConfiguratorQueryResult>(query);
    const { t } = useT();
    const pathname = getNodes(allPage).find((page) => page.locale === locale)?.pathname;

    return (
        <Section
            id={id}
            className={`${section} ${sectionConfigurator}  ${className}`}
            contentBoxClassName={sectionContentBox}
            theme="light"
            height="auto"
            circleDisplay="none"
            contentDisplay="left"
            titleProps={{
                children: t('section.configurator.title'),
                subtitle: t('section.configurator.subtitle'),
                subtitleColor: 'pink',
                className: title,
                subtitleClass: subtitle,
                weight: 'bold',
                size: 'large',
            }}
            textProps={{ children: t('section.configurator.text'), className: text }}
            image={image && getImage(image)}
            buttonProps={{
                children: t('section.configurator.button'),
                color: 'yellow',
                kind: 'normalDark',
                to: pathname || '',
                as: 'link',
            }}
        />
    );
};

const query = graphql`
    query {
        image: file(relativePath: { eq: "ecommerce-configurator.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        allPage(filter: { type: { eq: "contact" } }) {
            edges {
                node {
                    locale
                    pathname
                }
            }
        }
    }
`;

export default SectionEcommerceConfigurator;
