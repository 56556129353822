import React from 'react';
import { Link } from 'gatsby';

import {
    button,
    active,
    yellow,
    purple,
    pink,
    blue,
} from './offer-category-navigation-item.module.scss';
import { IOfferCategory } from '../../models/offer.model';
import { TAccentColor } from '../../models/accent-color.model';

export interface IOfferCategoryNavigationItemProps {
    className?: string;
    offerCategory: IOfferCategory;
}

const OfferCategoryNavigationItem: React.FC<IOfferCategoryNavigationItemProps> = ({
    className = '',
    offerCategory,
}) => {
    const { color, pathname } = offerCategory;

    const colorClass = colorClasses[color];

    return (
        <Link
            to={pathname}
            className={`${className} ${button} ${colorClass}`}
            activeClassName={active}
        >
            {offerCategory.name}
        </Link>
    );
};

const colorClasses: Record<TAccentColor, string> = {
    yellow,
    purple,
    pink,
    blue,
};

export default OfferCategoryNavigationItem;
