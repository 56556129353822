import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage, ImageDataLike } from 'gatsby-plugin-image';
import { IPage, usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import {
    section,
    sectionApaczka,
    sectionContent,
    container,
    header,
    accent,
    text,
} from './section-ecommerce-apaczka.module.scss';
import ApaczkaIcon from '../../assets/images/svg/apaczka.svg';
import { useT } from '../../hooks/use-translation';

import Section from '../hoc/section';
import Markdown from '../hoc/markdown';
import Title from '../atoms/title';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { getNodes } from '../../utils/get-nodes';

interface ISectionEcommerceApaczkaProps {
    className?: string;
    id?: string;
}

interface ISectionEcommerceApaczkaQueryResult {
    image: ImageDataLike;
    allPage: IQueryAllResult<Pick<IPage, 'locale' | 'pathname'>>;
}

const SectionEcommerceApaczka: React.FC<ISectionEcommerceApaczkaProps> = ({
    className = '',
    id,
}) => {
    const { locale } = usePageContext();
    const { image, allPage } = useStaticQuery<ISectionEcommerceApaczkaQueryResult>(query);
    const { t } = useT();

    const pathname = getNodes(allPage).find((page) => page.locale === locale)?.pathname || '';

    return (
        <Section
            id={id}
            className={`${section} ${sectionApaczka} ${className}`}
            contentClassName={sectionContent}
            theme="dark"
            height="auto"
            circleDisplay="none"
            contentDisplay="right"
            image={image && getImage(image)}
            buttonProps={{
                children: t('section.apaczka.button'),
                color: 'light',
                kind: 'outline',
                as: 'link',
                to: pathname,
            }}
        >
            <div className={container}>
                <div className={header}>
                    <ApaczkaIcon />
                    <Title weight="bold" size="large">
                        {t('section.apaczka.title.1')}{' '}
                        <span className={accent}>{t('section.apaczka.title.2')}</span>!
                    </Title>
                </div>
                <Markdown className={text}>{t('section.apaczka.text')}</Markdown>
            </div>
        </Section>
    );
};

const query = graphql`
    query {
        image: file(relativePath: { eq: "ecommerce-apaczka.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        allPage(filter: { type: { eq: "apaczka-alterpress" } }) {
            edges {
                node {
                    locale
                    pathname
                }
            }
        }
    }
`;

export default SectionEcommerceApaczka;
