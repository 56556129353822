import React, { useState } from 'react';
import AnimateHeight, { Height } from 'react-animate-height';

import {
    container,
    num,
    title,
    button,
    visibleText,
    hiddenText,
    content,
    header,
    pink,
    purple,
    blue,
    yellow,
    expanded,
} from './expandable-box.module.scss';
import { TAccentColor } from '../../models/accent-color.model';
import Markdown from '../hoc/markdown';

export interface IExpandableBox {
    title?: string;
    number?: string | number;
    visibleText?: string;
    hiddenText?: string;
    color?: TAccentColor;
}

interface IExpandableBoxProps {
    className?: string;
    TitleTag?: React.ElementType;
    box: IExpandableBox;
}

const ExpandableBox: React.FC<IExpandableBoxProps> = ({ className = '', box, TitleTag = 'h3' }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const colorClass = colorClasses[box.color || 'pink'];

    const handleToggle = () => setIsExpanded((prev) => !prev);

    return (
        <div className={`${container} ${className} ${colorClass} ${isExpanded ? expanded : ''}`}>
            <div className={content}>
                <div className={header}>
                    {box.number && <p className={num}>{box.number}</p>}
                    {box.title && <TitleTag className={title}>{box.title}</TitleTag>}
                    <button className={button} onClick={handleToggle} />
                </div>
                {box.visibleText && <p className={visibleText}>{box.visibleText}</p>}
                {box.hiddenText && (
                    <AnimateHeight height={isExpanded ? 'auto' : 1}>
                        <Markdown className={hiddenText}>{box.hiddenText}</Markdown>
                    </AnimateHeight>
                )}
            </div>
        </div>
    );
};

const colorClasses: Record<TAccentColor, string> = {
    pink: pink,
    purple: purple,
    blue: blue,
    yellow: yellow,
};

export default ExpandableBox;
