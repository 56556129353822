import React from 'react';

import {
    title,
    subtitle,
    columns,
    column,
    expandableBox,
    desktop,
    mobile,
} from './section-ecommerce-elements.module.scss';
import { useT } from '../../hooks/use-translation';

import Section from '../hoc/section';
import ExpandableBox, { IExpandableBox } from '../molecules/expandable-box';

const SectionEcommerceElements: React.FC = () => {
    const { t } = useT();

    const boxes: IExpandableBox[] = [
        {
            title: t('elements.1.title'),
            hiddenText: t('elements.1.hidden'),
            visibleText: t('elements.1.visible'),
            color: 'pink',
        },
        {
            title: t('elements.2.title'),
            hiddenText: t('elements.2.hidden'),
            visibleText: t('elements.2.visible'),
            color: 'pink',
        },
        {
            title: t('elements.3.title'),
            hiddenText: t('elements.3.hidden'),
            visibleText: t('elements.3.visible'),
            color: 'pink',
        },
        {
            title: t('elements.4.title'),
            hiddenText: t('elements.4.hidden'),
            visibleText: t('elements.4.visible'),
            color: 'pink',
        },
        {
            title: t('elements.5.title'),
            hiddenText: t('elements.5.hidden'),
            visibleText: t('elements.5.visible'),
            color: 'pink',
        },
        {
            title: t('elements.6.title'),
            hiddenText: t('elements.6.hidden'),
            visibleText: t('elements.6.visible'),
            color: 'pink',
        },
        {
            title: t('elements.7.title'),
            hiddenText: t('elements.7.hidden'),
            visibleText: t('elements.7.visible'),
            color: 'pink',
        },
        {
            title: t('elements.8.title'),
            hiddenText: t('elements.8.hidden'),
            visibleText: t('elements.8.visible'),
            color: 'pink',
        },
    ];

    return (
        <Section
            theme="dark"
            circleDisplay="none"
            titleProps={{
                children: t('elements.title'),
                subtitle: t('elements.subtitle'),
                className: title,
                subtitleColor: 'blue',
                subtitleClass: subtitle,
                size: 'large',
                weight: 'bold',
            }}
        >
            <div className={`${columns} ${desktop}`}>
                <div className={column}>
                    {boxes.map((box, index) => {
                        const boxNumber = index + 1;
                        const isOdd = boxNumber % 2 > 0;
                        if (!isOdd) return null;
                        return (
                            <ExpandableBox
                                className={expandableBox}
                                key={`box-odd-${boxNumber}`}
                                box={{ ...box, number: boxNumber }}
                            />
                        );
                    })}
                </div>
                <div className={column}>
                    {boxes.map((box, index) => {
                        const boxNumber = index + 1;
                        const isEven = boxNumber % 2 === 0;
                        if (!isEven) return null;
                        return (
                            <ExpandableBox
                                className={expandableBox}
                                key={`box-odd-${boxNumber}`}
                                box={{ ...box, number: boxNumber }}
                            />
                        );
                    })}
                </div>
            </div>
            <div className={`${columns} ${mobile}`}>
                {boxes.map((box, index) => {
                    const boxNumber = index + 1;
                    return (
                        <ExpandableBox
                            className={expandableBox}
                            key={`box-odd-${boxNumber}`}
                            box={{ ...box, number: boxNumber }}
                        />
                    );
                })}
            </div>
        </Section>
    );
};

export default SectionEcommerceElements;
