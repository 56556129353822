// extracted by mini-css-extract-plugin
export var container = "section-ecommerce-gains-module--container--SY72Z";
export var navButton = "section-ecommerce-gains-module--nav-button--0+cIH";
export var navigation = "section-ecommerce-gains-module--navigation--gnT7V";
export var section = "section-ecommerce-gains-module--section--c+Zwx";
export var sectionGains = "section-ecommerce-gains-module--section-gains--9IsUk";
export var slide = "section-ecommerce-gains-module--slide--EMau5";
export var slideInner = "section-ecommerce-gains-module--slide-inner--JUTCX";
export var slider = "section-ecommerce-gains-module--slider--lHid8";
export var subtitle = "section-ecommerce-gains-module--subtitle--ZiL4J";
export var title = "section-ecommerce-gains-module--title--5sfaD";