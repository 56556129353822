import React from 'react';

import { container, content, title, text } from './gain-card.module.scss';

export interface IGainCard {
    title: string;
    Icon: React.SVGFactory;
    text: string;
}

interface IGainCardProps {
    className?: string;
    card: IGainCard;
    TitleTag?: React.ElementType;
}

const GainCard: React.FC<IGainCardProps> = ({ card, className = '', TitleTag = 'h3' }) => {
    const Icon = card.Icon;
    return (
        <div className={`${container} ${className}`}>
            <Icon />
            <div className={content}>
                <TitleTag className={title}>{card.title}</TitleTag>
                <p className={text}>{card.text}</p>
            </div>
        </div>
    );
};

export default GainCard;
