import React from 'react';

import { IOfferCard } from '../../models/offer.model';
import { useT } from '../../hooks/use-translation';

import DefaultCard from './default-card';

interface IOfferCardProps {
    className?: string;
    offerCard: IOfferCard;
    TitleTag?: React.ElementType;
}

const OfferCard: React.FC<IOfferCardProps> = ({ className, offerCard, TitleTag = 'h2' }) => {
    const { t } = useT();
    const { pathname, category, title, lead } = offerCard;
    const { color = 'purple' } = category || {};

    return (
        <DefaultCard
            className={className}
            title={title || ''}
            TitleTag={TitleTag}
            description={lead}
            color={color}
            pathname={pathname}
            buttonText={t('button.love')}
        />
    );
};

export default OfferCard;
