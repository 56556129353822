// extracted by mini-css-extract-plugin
export var container = "case-study-card-module--container--GpLPv";
export var content = "case-study-card-module--content--8Fiqi";
export var contentTop = "case-study-card-module--content-top--2WOfE";
export var header = "case-study-card-module--header--Qdqlv";
export var img = "case-study-card-module--img--I8ScU";
export var imgBox = "case-study-card-module--img-box--5yI06";
export var imgContent = "case-study-card-module--img-content--1rZ9q";
export var logoBox = "case-study-card-module--logo-box--1dj2m";
export var specLabel = "case-study-card-module--spec-label--T0+CG";
export var specList = "case-study-card-module--spec-list--j12Tv";
export var specValue = "case-study-card-module--spec-value--z7hJT";
export var tagItem = "case-study-card-module--tag-item--WprEN";
export var tagList = "case-study-card-module--tag-list--KGpRM";
export var text = "case-study-card-module--text--2r6vM";