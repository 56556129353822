import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import { IPage, usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import {
    section,
    sectionFaqCta,
    container,
    inner,
    content,
    spacer,
    dark,
    imgBox,
    title,
} from './section-faq-cta.module.scss';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { useT } from '../../hooks/use-translation';
import { getNodes } from '../../utils/get-nodes';

import Section from '../hoc/section';
import Button, { TButtonProps } from '../atoms/button';
import Title, { ITitleProps } from '../atoms/title';

interface ISectionFaqCtaProps {
    className?: string;
    titleProps?: ITitleProps;
    buttonProps?: TButtonProps;
    id?: string;
}

interface ISectionFaqCtaQueryResult {
    image: ImageDataLike;
    allPage: IQueryAllResult<Pick<IPage, 'locale' | 'pathname'>>;
}

const SectionFaqCta: React.FC<ISectionFaqCtaProps> = ({
    className = '',
    buttonProps,
    titleProps,
    id,
}) => {
    const { locale } = usePageContext();
    const { image, allPage } = useStaticQuery<ISectionFaqCtaQueryResult>(query);
    const imageData = getImage(image);
    const { t } = useT();
    const pathname = getNodes(allPage).find((page) => page.locale === locale)?.pathname;

    return (
        <Section
            id={id}
            className={`${className} ${section} ${sectionFaqCta}`}
            theme="yellow"
            height="auto"
            circleDisplay="none"
            contentDisplay="left"
            contentWidth="full"
            gridType="wide"
        >
            <div className={container}>
                <div className={`${spacer} ${dark}`} />
                <div className={inner}>
                    <div className={imgBox}>
                        <div>
                            {imageData && (
                                <GatsbyImage alt="" image={imageData} objectFit="contain" />
                            )}
                        </div>
                    </div>
                    <div className={content}>
                        <Title
                            weight="bold"
                            {...titleProps}
                            className={`${title} ${titleProps?.className || ''}`}
                        >
                            {titleProps?.children || (
                                <>
                                    <span>{t('section.faq.cta.title.1')}</span>
                                    <br />
                                    <span>{t('section.faq.cta.title.2')}</span>
                                </>
                            )}
                        </Title>
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/*@ts-ignore*/}
                        <Button color="light" to={pathname || '/'} as="link" {...buttonProps}>
                            {buttonProps?.children || t('section.faq.cta.button')}
                        </Button>
                    </div>
                </div>
                <div className={spacer} />
            </div>
        </Section>
    );
};

const query = graphql`
    query {
        image: file(relativePath: { eq: "faq-cta.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        allPage(filter: { type: { eq: "faq-alterpress" } }) {
            edges {
                node {
                    pathname
                    locale
                }
            }
        }
    }
`;

export default SectionFaqCta;
