// extracted by mini-css-extract-plugin
export var blue = "expandable-box-module--blue--1ISAx";
export var button = "expandable-box-module--button---J3hf";
export var container = "expandable-box-module--container--igIVB";
export var content = "expandable-box-module--content--HQbwY";
export var expanded = "expandable-box-module--expanded--mjH8s";
export var header = "expandable-box-module--header--c+UAA";
export var hiddenText = "expandable-box-module--hidden-text--FKJ3E";
export var num = "expandable-box-module--num--t4B92";
export var pink = "expandable-box-module--pink--74srv";
export var purple = "expandable-box-module--purple--wfmB+";
export var title = "expandable-box-module--title--FWw-3";
export var yellow = "expandable-box-module--yellow--k8sGP";