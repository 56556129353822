import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage, ImageDataLike } from 'gatsby-plugin-image';

import {
    regular,
    accent,
    content,
    description,
    lead,
    sectionContent,
    title,
} from './section-ecommerce-intro.module.scss';
import { useT } from '../../hooks/use-translation';

import Section from '../hoc/section';
import Markdown from '../hoc/markdown';

interface ISectionEcommerceIntroQueryResult {
    image: ImageDataLike;
}

const SectionEcommerceIntro: React.FC = () => {
    const { image }: ISectionEcommerceIntroQueryResult = useStaticQuery(query);
    const { t } = useT();

    return (
        <Section
            height="auto"
            theme="light"
            circleDisplay="none"
            image={image && getImage(image)}
            contentDisplay="left"
            titleProps={{
                weight: 'bold',
                size: 'large',
                className: title,
                children: (
                    <>
                        <span className={regular}>{t('section.ecommerce.intro.title.1')}</span>{' '}
                        {t('section.ecommerce.intro.title.2')}{' '}
                        <span className={accent}>{t('section.ecommerce.intro.title.3')}</span>
                    </>
                ),
            }}
            buttonProps={{
                children: t('section.ecommerce.intro.button'),
                color: 'yellow',
                kind: 'normalDark',
                as: 'link',
                to: '#functionalities',
            }}
            contentClassName={sectionContent}
        >
            <div className={content}>
                <Markdown className={lead}>{t('section.ecommerce.intro.lead')}</Markdown>
                <p className={description}>{t('section.ecommerce.intro.description')}</p>
            </div>
        </Section>
    );
};

const query = graphql`
    query {
        image: file(relativePath: { eq: "ecommerce-intro.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 90)
            }
        }
    }
`;

export default SectionEcommerceIntro;
