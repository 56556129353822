import React from 'react';
import { graphql } from 'gatsby';

import {
    layout,
    hero,
    navigationSection,
    navigation,
    listSection,
    list,
    card,
} from './what-we-do.module.scss';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IOfferCard, IOfferCategory } from '../models/offer.model';
import { getNodes } from '../utils/get-nodes';
import { useT } from '../hooks/use-translation';

import MainLayout from '../layouts/main-layout';
import Hero from '../components/organisms/hero';
import Section from '../components/hoc/section';
import OfferCategoryNavigationItem from '../components/atoms/offer-category-navigation-item';
import OfferCard from '../components/molecules/offer-card';
import SectionEcommerceIntro from '../components/organisms/section-ecommerce-intro';
import SectionEcommerceElements from '../components/organisms/section-ecommerce-elemets';
import SectionEcommerceGains from '../components/organisms/section-ecommerce-gains';
import SectionContactCta from '../components/organisms/section-contact-cta';
import SectionEcommerceFunctionalities from '../components/organisms/section-ecommerce-functionalities';
import SectionEcommerceApaczka from '../components/organisms/section-ecommerce-apaczka';
import SectionEcommerceConfigurator from '../components/organisms/section-ecommerce-configurator';
import SectionFaqCta from '../components/organisms/section-faq-cta';
import SectionEcommerceCases from '../components/organisms/section-ecommerce-cases';
import SectionEcommerceNavigation from '../components/organisms/section-ecommerce-navigation';

interface IWhatWeDoPageProps {
    readonly pageContext: {
        offerCategoryId?: number;
    };
    readonly data: {
        offerCategory: IOfferCategory;
        allOffer: IQueryAllResult<IOfferCard>;
        allOfferCategory: IQueryAllResult<IOfferCategory>;
    };
}

const CONTACT_FORM_ID = 'ecommerce-contact-form';
const ECOMMERCE_CATEGORY_NAME = 'eCommerce';

const WhatWeDoPage: React.FC<IWhatWeDoPageProps> = ({ data, pageContext }) => {
    const { offerCategoryId } = pageContext;
    const { t } = useT();
    const { allOffer, allOfferCategory, offerCategory } = data;
    const offerCards = getNodes(allOffer);
    const offerCategories = getNodes(allOfferCategory);

    const isEcommercePage = offerCategory?.name === ECOMMERCE_CATEGORY_NAME;

    return (
        <MainLayout
            className={layout}
            showFloatingCircles={false}
            {...(isEcommercePage
                ? {
                      showContactForm: true,
                      contactFormProps: {
                          id: CONTACT_FORM_ID,
                          buttonProps: { color: 'yellow', kind: 'normalDark' },
                          titleProps: { children: t('ecommerce.contact.title') },
                          description: t('ecommerce.contact.description'),
                      },
                  }
                : {})}
        >
            <Hero
                className={hero}
                titleProps={{ children: t('what.hero.title'), Tag: 'h1' }}
                color="yellow"
            />
            <Section
                className={navigationSection}
                theme="dark"
                circleDisplay="none"
                height="auto"
                contentDisplay="center"
            >
                <ul className={navigation}>
                    {offerCategories.map((offerCategory) => {
                        return (
                            <li key={offerCategory.offerCategoryId}>
                                <OfferCategoryNavigationItem offerCategory={offerCategory} />
                            </li>
                        );
                    })}
                </ul>
            </Section>
            {offerCategoryId && !isEcommercePage && (
                <Section
                    className={listSection}
                    circleDisplay="none"
                    height="auto"
                    contentDisplay="center"
                    theme="light"
                >
                    <ul className={list}>
                        {offerCards.map((offerCard) => {
                            return (
                                <li key={`offer-item-${offerCard.offerId}`}>
                                    <OfferCard className={card} offerCard={offerCard} />
                                </li>
                            );
                        })}
                    </ul>
                </Section>
            )}
            {offerCategoryId && isEcommercePage && (
                <>
                    <SectionEcommerceNavigation isNavToId={true} showGoBackLink={false} />
                    <SectionEcommerceIntro />
                    <SectionEcommerceElements />
                    <SectionEcommerceGains />
                    <SectionContactCta />
                    <SectionEcommerceFunctionalities
                        id="functionalities"
                        buttonProps={{ as: 'link', to: `#${CONTACT_FORM_ID}` }}
                    />
                    <SectionEcommerceApaczka id="apaczka" />
                    <SectionEcommerceConfigurator id="configurator" />
                    <SectionFaqCta id="faq" />
                    <SectionEcommerceCases id="cases" />
                </>
            )}
        </MainLayout>
    );
};

export const query = graphql`
    query WhatWeDoPage($locale: String!, $offerCategoryId: Int) {
        offerCategory(locale: { eq: $locale }, offerCategoryId: { eq: $offerCategoryId }) {
            ...offerCategoryFields
        }
        allOffer(
            filter: {
                locale: { eq: $locale }
                category: { offerCategoryId: { eq: $offerCategoryId } }
            }
            sort: { fields: sequence, order: ASC }
        ) {
            edges {
                node {
                    ...offerCardFields
                }
            }
        }
        allOfferCategory(
            filter: { locale: { eq: $locale } }
            sort: { fields: sequence, order: ASC }
        ) {
            edges {
                node {
                    ...offerCategoryFields
                }
            }
        }
    }
`;

export default WhatWeDoPage;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';
