import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';

import {
    section,
    sectionGains,
    title,
    subtitle,
    slider,
    slideInner,
    slide,
    navigation,
    navButton,
    container,
} from './section-ecommerce-gains.module.scss';
import BinaryIcon from '../../assets/images/svg/binary.svg';
import CareIcon from '../../assets/images/svg/care.svg';
import RocketIcon from '../../assets/images/svg/rokcet.svg';
import ShieldIcon from '../../assets/images/svg/double-shield.svg';
import LockIcon from '../../assets/images/svg/lock.svg';
import MaintenanceIcon from '../../assets/images/svg/server-maintenance.svg';
import GuaranteeIcon from '../../assets/images/svg/guarantee.svg';
import SupportIcon from '../../assets/images/svg/support.svg';
import ArrowLeft from '../../assets/images/svg/arrow-left.svg';
import ArrowRight from '../../assets/images/svg/arrow-right.svg';
import { useT } from '../../hooks/use-translation';

import GainCard, { IGainCard } from '../molecules/gain-card';
import Section from '../hoc/section';

const SectionEcommerceGains: React.FC = () => {
    const { t } = useT();

    const gainCards: IGainCard[] = [
        {
            title: t('section.gains.card.title.1'),
            Icon: BinaryIcon,
            text: t('section.gains.card.text.1'),
        },
        {
            title: t('section.gains.card.title.2'),
            Icon: CareIcon,
            text: t('section.gains.card.text.2'),
        },
        {
            title: t('section.gains.card.title.3'),
            Icon: RocketIcon,
            text: t('section.gains.card.text.3'),
        },
        {
            title: t('section.gains.card.title.4'),
            Icon: ShieldIcon,
            text: t('section.gains.card.text.4'),
        },
        {
            title: t('section.gains.card.title.5'),
            Icon: LockIcon,
            text: t('section.gains.card.text.5'),
        },
        {
            title: t('section.gains.card.title.6'),
            Icon: MaintenanceIcon,
            text: t('section.gains.card.text.6'),
        },
        {
            title: t('section.gains.card.title.7'),
            Icon: GuaranteeIcon,
            text: t('section.gains.card.text.7'),
        },
        {
            title: t('section.gains.card.title.8'),
            Icon: SupportIcon,
            text: t('section.gains.card.text.8'),
        },
    ];

    return (
        <Section
            className={`${section} ${sectionGains}`}
            theme="light"
            circleDisplay="none"
            contentDisplay="left"
            titleProps={{
                children: t('section.gains.title'),
                subtitle: t('section.gains.subtitle'),
                subtitleColor: 'pink',
                className: title,
                subtitleClass: subtitle,
                size: 'large',
                weight: 'bold',
            }}
        >
            <div className={container}>
                <div className={navigation}>
                    <button className={`${navButton} gains-slider-nav-button-left`}>
                        <ArrowLeft />
                    </button>
                    <button className={`${navButton} gains-slider-nav-button-right`}>
                        <ArrowRight />
                    </button>
                </div>
                <Swiper
                    className={slider}
                    modules={[Navigation]}
                    slidesPerView="auto"
                    breakpoints={{ 0: { spaceBetween: 20 }, 600: { spaceBetween: 30 } }}
                    navigation={{
                        prevEl: '.gains-slider-nav-button-left',
                        nextEl: '.gains-slider-nav-button-right',
                    }}
                >
                    {gainCards
                        .reduce((acc, item) => {
                            if (
                                !acc[0] ||
                                (acc[acc.length - 1] && acc[acc.length - 1].length === 2)
                            ) {
                                acc.push([item]);
                            } else {
                                acc[acc.length - 1][1] = item;
                            }
                            return acc;
                        }, [] as IGainCard[][])
                        .map((cards, index) => {
                            return (
                                <SwiperSlide key={`cards-${index}`} className={slide}>
                                    <div className={slideInner}>
                                        {cards.map((card, index) => {
                                            return <GainCard key={`card-${index}`} card={card} />;
                                        })}
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                </Swiper>
            </div>
        </Section>
    );
};

export default SectionEcommerceGains;
