import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { IPage, usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import {
    section,
    sectionContactCta,
    container,
    spacer,
    inner,
    content,
    header,
    paragraph,
    right,
} from './section-contact-cta.module.scss';
import ApLogoSquare from '../../assets/images/svg/logo-ap-square.svg';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { useT } from '../../hooks/use-translation';
import { getNodes } from '../../utils/get-nodes';

import Section from '../hoc/section';
import Button, { TButtonProps } from '../atoms/button';
import Title, { ITitleProps } from '../atoms/title';

interface ISectionContactCtaProps {
    className?: string;
    titleProps?: ITitleProps;
    text?: string;
    buttonProps?: TButtonProps;
}

interface ISectionContactCtaQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'locale' | 'pathname'>>;
}

const SectionContactCta: React.FC<ISectionContactCtaProps> = ({
    className = '',
    buttonProps,
    text,
    titleProps,
}) => {
    const { t } = useT();
    const { locale } = usePageContext();
    const { allPage } = useStaticQuery<ISectionContactCtaQueryResult>(query);
    const pathname = getNodes(allPage).find((page) => page.locale === locale)?.pathname;

    return (
        <Section
            className={`${className} ${section} ${sectionContactCta}`}
            theme="light"
            height="auto"
            circleDisplay="none"
            contentDisplay="left"
            contentWidth="full"
        >
            <div className={container}>
                <div className={`${spacer} ${right}`} />
                <div className={inner}>
                    <div className={content}>
                        <div className={header}>
                            <ApLogoSquare />
                            <Title weight="bold" {...titleProps}>
                                {titleProps?.children || t('section.contact.cta.title')}
                            </Title>
                        </div>
                        <p className={paragraph}>{text || t('section.contact.cta.text')}</p>
                    </div>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/*@ts-ignore*/}
                    <Button as="link" to={pathname} color="light" {...buttonProps}>
                        {buttonProps?.children || t('section.contact.cta.button')}
                    </Button>
                </div>
                <div className={spacer} />
            </div>
        </Section>
    );
};

const query = graphql`
    query {
        allPage(filter: { type: { eq: "contact" } }) {
            edges {
                node {
                    pathname
                    locale
                }
            }
        }
    }
`;

export default SectionContactCta;
