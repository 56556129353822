// extracted by mini-css-extract-plugin
export var accent = "section-ecommerce-cases-module--accent--Zeug8";
export var button = "section-ecommerce-cases-module--button--Cbyr4";
export var container = "section-ecommerce-cases-module--container--QxCXY";
export var navButton = "section-ecommerce-cases-module--nav-button--48wDU";
export var navigation = "section-ecommerce-cases-module--navigation--dXIGX";
export var section = "section-ecommerce-cases-module--section--WEGux";
export var sectionCases = "section-ecommerce-cases-module--section-cases--+N1Nk";
export var slideCard = "section-ecommerce-cases-module--slide-card--lgcI3";
export var slider = "section-ecommerce-cases-module--slider--pMlpo";
export var subtitle = "section-ecommerce-cases-module--subtitle--Ap7JV";
export var title = "section-ecommerce-cases-module--title--t53AQ";